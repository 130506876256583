<template>
  <div>
    <!-- Mobile layout -->
    <responsive-layout
      v-if="$vuetify.breakpoint.mobile"
      poster="https://vod.eventcdn.net/pandora/smc2021/MeetYourColleagues_Bg.png"
    >
      <v-sheet
        style="min-height: 100vh"
        class="d-flex flex-column justify-center transparent"
        dark
      >
        <div class="main py-8 flex-fill">
          <v-container fluid>
            <!-- 'Breadcrumb' navigation at the top -->
            <v-row justify="start" align="start" class="mt-5 lobby-router-row">
              <v-col
                cols="12"
                class="pl-0 d-flex align-start justify-start flex-column ml-1"
              >
                <!--<v-btn
                  class="ma-2 white--text lobby-back-button rounded-0 mr-0 mb-0"
                  color="black"
                >
                  <router-link class="white--text" to="/lobby">
                    <v-icon left dark color="pink" class="back-arrow-theatre">
                      fas fa-chevron-left
                    </v-icon>
                    <span class="back-arrow-lobby-link">To lobby</span>
                  </router-link>
                </v-btn>-->
                <AppBackButton class="ml-2" :to="{ name: 'Lobby' }"
                  >TO LOBBY</AppBackButton
                >
                <div class="route-name-background-container-information ml-2">
                  <span class="theatre-route-name">{{ $route.meta.name }}</span>
                </div>
              </v-col>
            </v-row>

            <!-- Break out rooms section -->
            <v-row
              justify="start"
              align="start"
              class="mt-5 sessions-agenda-lobby-row"
            >
              <v-col cols="12">
                <div
                  class="pl-1 py-3 black text-left white--text"
                  style="font-size: 1.2rem"
                >
                  <h3 class="pa-4">BREAK-OUT ROOMS</h3>
                </div>
                <div class="py-6 d-flex white pl-2 pr-4">
                  <v-col cols="12" class="pl-3 pr-3">
                    <p class="black--text text-left">
                      Unfortunately Break-out Rooms are only available from full
                      site.
                    </p>
                  </v-col>
                </div>

                <!-- Sessions in agenda section -->

                <v-col cols="12" class="pb-0 pr-0 pl-0 mt-4">
                  <div
                    class="pl-1 py-3 black text-left white--text"
                    style="font-size: 1.2rem"
                  >
                    <h3 class="pa-4">BROWSE YOUR COLLEAGUES</h3>
                  </div>
                  <div class="py-6 d-flex white pl-2 pr-2">
                    <v-col cols="12">
                      <v-row
                        justify="start"
                        align="center"
                        class="mt-5 mb-5 sessions-agenda-lobby-row ml-1"
                      >
                        <span
                          class="filter-text-meet-your-colleagues mr-3"
                          style="
                            font: var(--unnamed-font-style-normal) normal
                              var(--unnamed-font-weight-normal)
                              var(--unnamed-font-size-16) /
                              var(--unnamed-line-spacing-26)
                              var(--unnamed-font-family-pan-text) !important;
                            letter-spacing: var(
                              --unnamed-character-spacing-0
                            ) !important;
                            color: var(--v-darker-grey-base) !important;
                            font: normal normal normal 16px/26px Pan Text !important;
                          "
                          >Filter by Interests:</span
                        >
                        <v-chip-group multiple v-model="filters.interests">
                          <v-chip
                            v-for="(interest, idx) in availableInterests"
                            :key="idx"
                            active-class="black white--text"
                            :value="interest"
                            class="
                              white--text
                              rounded-0
                              elevation-0
                              font-weight-bold
                            "
                          >
                            {{ interest }}
                          </v-chip>
                        </v-chip-group>
                      </v-row>
                      <v-data-iterator
                        :items="filteredColleagues"
                        :items-per-page.sync="itemsPerPage"
                        :page.sync="page"
                        :search="search"
                        :sort-by="sortBy.toLowerCase()"
                        :sort-desc="sortDesc"
                        hide-default-footer
                        class="d-flex flex-row flex-wrap"
                      >
                        <template v-slot:header>
                          <v-col cols="12" class="d-flex pl-1 pr-1">
                            <v-text-field
                              v-model="search"
                              clearable
                              hide-details
                              label="Search by name"
                              class="meet-your-colleagues-search-bar"
                              style="
                                font: var(--unnamed-font-style-normal) normal
                                  var(--unnamed-font-weight-normal)
                                  var(--unnamed-font-size-16) /
                                  var(--unnamed-line-spacing-26)
                                  var(--unnamed-font-family-pan-text) !important;
                                letter-spacing: var(
                                  --unnamed-character-spacing-0
                                ) !important;
                                color: var(--v-grey-base) !important;
                                font: normal normal normal 16px/26px Pan Text !important;
                              "
                              light
                            ></v-text-field>
                          </v-col>

                          <v-col
                            cols="12"
                            class="d-flex colleagues-count pl-1 mt-4"
                          >
                            <p
                              style="
                                font: var(--unnamed-font-style-normal) normal
                                  var(--unnamed-font-weight-normal)
                                  var(--unnamed-font-size-18) / 32px
                                  var(--unnamed-font-family-pan-text) !important;
                                letter-spacing: var(
                                  --unnamed-character-spacing-0
                                ) !important;
                                color: var(--v-grey-base) !important;
                                font: normal normal normal 18px/32px Pan Text !important;
                              "
                            >
                              {{ itemsPerPage }} /
                              {{ filteredColleagues.length }} Colleagues
                            </p>
                          </v-col>
                        </template>

                        <template v-slot:default="props">
                          <v-row class="pa-4 pt-3 pr-3">
                            <v-col
                              v-for="item in props.items"
                              :key="item.id"
                              cols="12"
                              class="
                                agenda-container
                                mt-4
                                mb-4
                                d-flex
                                align-center
                                pa-0
                                white
                              "
                            >
                              <v-col
                                cols="12"
                                class="d-flex mr-1 pa-1 pt-0 pb-0"
                                style="
                                  background: var(--v-lighter-grey-base) 0% 0%
                                    no-repeat padding-box;
                                  border-left: 3px solid var(--v-pink-base) !important;
                                "
                              >
                                <v-col
                                  cols="6"
                                  class="d-flex pt-0 pb-0 align-center"
                                >
                                  <router-link
                                    :to="{
                                      name: 'UserPage',
                                      params: { id: item.id },
                                    }"
                                  >
                                    <p class="downloads-title mb-0">
                                      {{ item.name }}
                                    </p>
                                  </router-link>
                                </v-col>
                                <v-col
                                  cols="6"
                                  class="
                                    d-flex
                                    align-center
                                    justify-end
                                    pr-0
                                    pt-0
                                    pb-0
                                  "
                                >
                                  <router-link
                                    :to="{
                                      name: 'UserPage',
                                      params: { id: item.id },
                                    }"
                                  >
                                    <v-icon class="mb-0" dark color="black">
                                      fas fa-ellipsis-h
                                    </v-icon>
                                  </router-link>
                                  <v-btn
                                    color="black"
                                    class="
                                      rounded-0
                                      downloads-button
                                      ml-4
                                      elevation-0
                                    "
                                    @click="onChatClikc(item.id)"
                                  >
                                    <v-icon dark color="pink">
                                      fas fa-comments
                                    </v-icon>
                                  </v-btn>
                                </v-col>
                              </v-col>
                            </v-col>
                          </v-row>
                        </template>

                        <template v-slot:footer>
                          <v-row
                            class="pa-1 pr-2 mt-2"
                            align="center"
                            justify="start"
                          >
                            <!--<v-col cols="12" class="d-flex align-center">
                              <span class="grey--text">Items per page</span>
                              <v-menu offset-y>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    dark
                                    color="pink"
                                    class="
                                      ml-2
                                      rounded-0
                                      elevation-0
                                      black--text
                                      align-center
                                    "
                                    v-bind="attrs"
                                    v-on="on"
                                  >
                                    {{ itemsPerPage }}
                                    <v-icon
                                      dark
                                      color="black"
                                      small
                                      class="ml-1"
                                    >
                                      fas fa-chevron-down
                                    </v-icon>
                                  </v-btn>
                                </template>
                                <v-list>
                                  <v-list-item
                                    v-for="(number, index) in itemsPerPageArray"
                                    :key="index"
                                    @click="updateItemsPerPage(number)"
                                  >
                                    <v-list-item-title>{{
                                      number
                                    }}</v-list-item-title>
                                  </v-list-item>
                                </v-list>
                              </v-menu>
                            </v-col>-->

                            <v-col
                              cols="12"
                              class="d-flex justify-start align-center"
                            >
                              <span class="mr-4 grey--text">
                                Page {{ page }} of {{ numberOfPages }}
                              </span>
                              <v-btn
                                fab
                                dark
                                color="pink"
                                class="mr-1"
                                @click="formerPage"
                              >
                                <v-icon dark color="black" small>
                                  fas fa-chevron-left
                                </v-icon>
                              </v-btn>
                              <v-btn
                                fab
                                dark
                                color="pink"
                                class="ml-1"
                                @click="nextPage"
                              >
                                <v-icon dark color="black" small>
                                  fas fa-chevron-right
                                </v-icon>
                              </v-btn>
                            </v-col>
                          </v-row>
                        </template>
                      </v-data-iterator>
                    </v-col>
                  </div>
                </v-col>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </v-sheet>
    </responsive-layout>

    <!-- 16:9 layout for desktop devices -->
    <aspect-ratio-layout
      v-else
      poster="https://vod.eventcdn.net/pandora/smc2021/backgrounds/MeetYourColleagues_loop.jpg"
      background-video-initial="https://vod.eventcdn.net/pandora/smc2021/BackgroundVideo/MeetYourColleagues.mp4"
      background-video-loop="https://vod.eventcdn.net/pandora/smc2021/BackgroundVideo/loop/MeetYourColleagues_loop.mp4"
      #default="{ replayBackgroundVideo, isLooping }"
    >
      <v-sheet class="d-flex flex-column transparent" light>
        <div class="main">
          <template v-if="isLooping">
            <div style="position: fixed; right: 353px; top: 69%">
              <div style="position: relative" class="d-flex align-center">
                <v-btn
                  color="black"
                  class="play-btn pr-7"
                  style="position: unset"
                >
                  <span class="white--text play-btn-text">WELCOME</span>
                </v-btn>

                <v-btn
                  fab
                  class="rounded-circle ml-n4"
                  color="white"
                  @click="replayBackgroundVideo"
                  style="z-index: 16"
                >
                  <v-icon dark color="pink"> fas fa-play </v-icon>
                </v-btn>
              </div>
            </div>
          </template>
          <v-container fluid>
            <!-- 'Breadcrumb' navigation at the top -->
            <v-row justify="start" align="start" class="mt-5 lobby-router-row">
              <v-col
                cols="8"
                class="pl-0 d-flex align-start justify-start ml-10"
              >
                <!--<v-btn
                  class="ma-2 white--text lobby-back-button rounded-0 mr-0"
                  color="black"
                >
                  <router-link class="white--text" to="/lobby">
                    <v-icon left dark color="pink" class="back-arrow-theatre">
                      fas fa-chevron-left
                    </v-icon>
                    <span class="back-arrow-lobby-link">To lobby</span>
                  </router-link>
                </v-btn>-->
                <AppBackButton class="mt-2 ml-2" :to="{ name: 'Lobby' }"
                  >TO LOBBY</AppBackButton
                >
                <div
                  class="
                    route-name-background-container-meet-your-colleagues
                    mt-2
                  "
                >
                  <span class="theatre-route-name">{{ $route.meta.name }}</span>
                </div>
              </v-col>
            </v-row>

            <!-- Break out rooms section -->
            <v-row
              justify="start"
              align="stretch"
              class="mt-5 sessions-agenda-lobby-row"
            >
              <v-col cols="8">
                <div
                  class="pl-6 py-3 black text-left white--text"
                  style="font-size: 1.2rem"
                >
                  <h3 class="pa-4">BREAK-OUT ROOMS</h3>
                </div>
                <v-container fluid class="white">
                  <v-row>
                    <v-col
                      cols="12"
                      class="pa-8 pb-0 d-flex flex-row flex-wrap"
                    >
                      <p class="text-left">
                        Dare to network directly with your colleagues? Feel free
                        to jump into any of our live video rooms to start or
                        join a conversation. If a room is full, please check in
                        at a later time for availability to join.
                      </p></v-col
                    >
                  </v-row>
                  <v-row class="px-8" style="gap: 0.5rem">
                    <v-col
                      cols="3"
                      v-for="breakoutroom in breakoutrooms"
                      :key="breakoutroom.id"
                      class="
                        agenda-container
                        my-4
                        d-flex
                        align-center
                        pa-0
                        flex-row flex-wrap
                        rounded-lg
                      "
                      style="max-width: calc(25% - 1rem)"
                    >
                      <div
                        class="
                          d-flex
                          align-stretch
                          justify-center
                          pa-4
                          black
                          rounded-t-lg
                        "
                        style="min-height: 90px; width: 100%"
                      >
                        <p
                          class="
                            breakout-rooms-title
                            text-center
                            primary--text
                            mb-0
                            text-uppercase
                          "
                        >
                          {{ breakoutroom.name }}
                        </p>
                      </div>
                      <div
                        class="d-flex align-center justify-center pa-5"
                        style="width: 100%"
                      >
                        <template v-if="event.state !== 'ONDEMAND'">
                          <v-btn
                            v-if="
                              breakoutroom.current_participants <=
                              breakoutroom.max_participants
                            "
                            color="pink"
                            class="
                              rounded-0
                              font-weight-bold
                              breakout-room-button
                            "
                            depressed
                            :to="{
                              name: 'BreakoutRoom',
                              params: { id: breakoutroom.id },
                            }"
                          >
                            <span class="join-button">JOIN</span>
                          </v-btn>

                          <p v-else>OOPS, THIS ROOM IS FULL</p>
                        </template>

                        <!-- On Demand -->
                        <template v-else>
                          <p>CLOSED</p>
                        </template>
                      </div>
                    </v-col>
                  </v-row>
                </v-container>

                <!-- BROWSE YOUR COLLEAGUES section -->

                <v-col
                  cols="12"
                  class="pb-0 pr-0 pl-0 mt-4"
                  style="margin-bottom: 150px"
                >
                  <div
                    class="pl-6 py-3 black text-left white--text"
                    style="font-size: 1.2rem"
                  >
                    <h3 class="pa-4">BROWSE YOUR COLLEAGUES</h3>
                  </div>
                  <div class="py-6 d-flex white pl-6 pr-4">
                    <v-col cols="12">
                      <v-row
                        justify="start"
                        align="center"
                        class="mt-5 mb-5 sessions-agenda-lobby-row ml-1"
                      >
                        <span
                          class="filter-text-meet-your-colleagues mr-3"
                          style="
                            font: var(--unnamed-font-style-normal) normal
                              var(--unnamed-font-weight-normal)
                              var(--unnamed-font-size-16) /
                              var(--unnamed-line-spacing-26)
                              var(--unnamed-font-family-pan-text) !important;
                            letter-spacing: var(
                              --unnamed-character-spacing-0
                            ) !important;
                            color: var(--v-darker-grey-base) !important;
                            font: normal normal normal 16px/26px Pan Text !important;
                          "
                          >Filter by Interests:</span
                        >

                        <v-chip-group multiple v-model="filters.interests">
                          <v-chip
                            v-for="(interest, idx) in availableInterests"
                            :key="idx"
                            active-class="black white--text"
                            :value="interest"
                            class="
                              white--text
                              rounded-0
                              elevation-0
                              font-weight-bold
                            "
                          >
                            {{ interest }}
                          </v-chip>
                        </v-chip-group>
                      </v-row>
                      <v-data-iterator
                        :items="filteredColleagues"
                        :items-per-page.sync="itemsPerPage"
                        :page.sync="page"
                        :search="search"
                        :sort-by="sortBy.toLowerCase()"
                        :sort-desc="sortDesc"
                        hide-default-footer
                        class="d-flex flex-row flex-wrap"
                      >
                        <template v-slot:header>
                          <v-col cols="12" md="4" class="d-flex pl-1">
                            <v-text-field
                              v-model="filters.name"
                              clearable
                              hide-details
                              label="Search by Name"
                              class="meet-your-colleagues-search-bar"
                              style="
                                font: var(--unnamed-font-style-normal) normal
                                  var(--unnamed-font-weight-normal)
                                  var(--unnamed-font-size-16) /
                                  var(--unnamed-line-spacing-26)
                                  var(--unnamed-font-family-pan-text) !important;
                                letter-spacing: var(
                                  --unnamed-character-spacing-0
                                ) !important;
                                color: var(--v-grey-base) !important;
                                font: normal normal normal 16px/26px Pan Text !important;
                              "
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" md="4" class="d-flex">
                            <v-text-field
                              v-model="filters.store_name"
                              clearable
                              hide-details
                              label="Search by Store Name"
                              class="meet-your-colleagues-search-bar"
                              style="
                                font: var(--unnamed-font-style-normal) normal
                                  var(--unnamed-font-weight-normal)
                                  var(--unnamed-font-size-16) /
                                  var(--unnamed-line-spacing-26)
                                  var(--unnamed-font-family-pan-text) !important;
                                letter-spacing: var(
                                  --unnamed-character-spacing-0
                                ) !important;
                                color: var(--v-grey-base) !important;
                                font: normal normal normal 16px/26px Pan Text !important;
                              "
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" md="4" class="d-flex">
                            <v-text-field
                              v-model="filters.registration_type"
                              clearable
                              hide-details
                              label="Search by Registration Type"
                              class="meet-your-colleagues-search-bar"
                              style="
                                font: var(--unnamed-font-style-normal) normal
                                  var(--unnamed-font-weight-normal)
                                  var(--unnamed-font-size-16) /
                                  var(--unnamed-line-spacing-26)
                                  var(--unnamed-font-family-pan-text) !important;
                                letter-spacing: var(
                                  --unnamed-character-spacing-0
                                ) !important;
                                color: var(--v-grey-base) !important;
                                font: normal normal normal 16px/26px Pan Text !important;
                              "
                            ></v-text-field>
                          </v-col>
                          <v-col
                            cols="12"
                            class="d-flex colleagues-count pl-1 mt-4"
                          >
                            <p
                              style="
                                font: var(--unnamed-font-style-normal) normal
                                  var(--unnamed-font-weight-normal)
                                  var(--unnamed-font-size-18) / 32px
                                  var(--unnamed-font-family-pan-text) !important;
                                letter-spacing: var(
                                  --unnamed-character-spacing-0
                                ) !important;
                                color: var(--v-grey-base) !important;
                                font: normal normal normal 18px/32px Pan Text !important;
                              "
                            >
                              {{ itemsPerPage }} /
                              {{ filteredColleagues.length }} Colleagues
                            </p>
                          </v-col>

                          <template v-if="$vuetify.breakpoint.mdAndUp">
                            <!-- <v-select
                                v-model="sortBy"
                                flat
                                solo-inverted
                                hide-details
                                :items="keys"
                                prepend-inner-icon="mdi-magnify"
                                label="Sort by"
                              ></v-select> -->

                            <!-- <v-spacer></v-spacer> -->
                            <!-- <v-btn-toggle v-model="sortDesc" mandatory>
                              <v-btn
                                large
                                depressed
                                color="blue"
                                :value="false"
                              >
                                <v-icon>mdi-arrow-up</v-icon>
                              </v-btn>
                              <v-btn large depressed color="blue" :value="true">
                                <v-icon>mdi-arrow-down</v-icon>
                              </v-btn>
                            </v-btn-toggle> -->
                          </template>
                        </template>

                        <template v-slot:default="props">
                          <v-row class="pa-4 pt-3 pr-8">
                            <v-col
                              v-for="item in props.items"
                              :key="item.id"
                              cols="12"
                              class="
                                agenda-container
                                mt-4
                                mb-4
                                d-flex
                                align-center
                                pa-0
                                white
                              "
                            >
                              <!-- <v-card>
                                <v-card-title
                                  class="subheading font-weight-bold"
                                >
                                  {{ item.title }}
                                </v-card-title>

                                <v-divider></v-divider>

                                <v-list dense>
                                  <v-list-item
                                    v-for="(key, index) in filteredKeys"
                                    :key="index"
                                  >
                                    <v-list-item-content
                                      :class="{
                                        'blue--text testhest': sortBy === key,
                                      }"
                                    >
                                      {{ key }}:
                                    </v-list-item-content>
                                    <v-list-item-content
                                      class="align-end"
                                      :class="{
                                        'blue--text ged': sortBy === key,
                                      },"
                                    >
                                      {{ item[key.toLowerCase()] }}
                                      {{ item.title }}
                                    </v-list-item-content>
                                  </v-list-item>
                                </v-list>
                              </v-card> -->

                              <v-col
                                cols="4"
                                class="d-flex mr-1 pa-1 pt-0 pb-0"
                                style="
                                  background: var(--v-lighter-grey-base) 0% 0%
                                    no-repeat padding-box;
                                  border-left: 3px solid var(--v-pink-base) !important;

                                  height: 100%;
                                "
                              >
                                <v-col
                                  cols="9"
                                  class="d-flex pt-0 pb-0 align-center"
                                >
                                  <router-link
                                    :to="{
                                      name: 'UserPage',
                                      params: { id: item.id },
                                    }"
                                  >
                                    <p class="downloads-title mb-0">
                                      {{ item.name }}
                                    </p>
                                  </router-link>
                                </v-col>
                                <v-col
                                  cols="3"
                                  class="
                                    d-flex
                                    align-center
                                    justify-end
                                    pr-0
                                    pt-0
                                    pb-0
                                  "
                                >
                                  <router-link
                                    :to="{
                                      name: 'UserPage',
                                      params: { id: item.id },
                                    }"
                                  >
                                    <v-icon class="mb-0" dark color="black">
                                      fas fa-ellipsis-h
                                    </v-icon>
                                  </router-link>
                                  <v-btn
                                    color="black"
                                    class="
                                      rounded-0
                                      downloads-button
                                      ml-4
                                      elevation-0
                                    "
                                    @click="onChatClick(item.id)"
                                  >
                                    <v-icon dark color="pink">
                                      fas fa-comments
                                    </v-icon>
                                  </v-btn>
                                </v-col>
                              </v-col>

                              <v-col
                                cols="4"
                                class="d-flex mr-1 pa-4 pt-0 pb-0"
                                style="
                                  background: var(--v-lighter-grey-base) 0% 0%
                                    no-repeat padding-box;
                                  height: 100%;
                                "
                              >
                                <v-col
                                  cols="12"
                                  class="d-flex pt-0 pb-0 align-center"
                                >
                                  <p class="downloads-title mb-0">
                                    {{ item.store_name }}
                                  </p>
                                </v-col>
                              </v-col>

                              <v-col
                                cols="4"
                                class="d-flex pa-4"
                                style="
                                  background: var(--v-lighter-grey-base) 0% 0%
                                    no-repeat padding-box;
                                  height: 100%;
                                "
                              >
                                <v-col
                                  cols="12"
                                  class="d-flex pt-0 pb-0 align-center"
                                >
                                  <p class="downloads-title mb-0">
                                    {{ item.registration_type }}
                                  </p>
                                </v-col>
                              </v-col>
                            </v-col>
                          </v-row>
                        </template>

                        <template v-slot:footer>
                          <v-row
                            class="pa-1 pr-2 mt-2"
                            align="center"
                            justify="start"
                          >
                            <!--<v-col cols="6" class="d-flex align-center">
                              <span class="grey--text">Items per page</span>
                              <v-menu offset-y>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    dark
                                    color="pink"
                                    class="
                                      ml-2
                                      rounded-0
                                      elevation-0
                                      black--text
                                      align-center
                                    "
                                    v-bind="attrs"
                                    v-on="on"
                                  >
                                    {{ itemsPerPage }}
                                    <v-icon
                                      dark
                                      color="black"
                                      small
                                      class="ml-1"
                                    >
                                      fas fa-chevron-down
                                    </v-icon>
                                  </v-btn>
                                </template>
                                <v-list>
                                  <v-list-item
                                    v-for="(number, index) in itemsPerPageArray"
                                    :key="index"
                                    @click="updateItemsPerPage(number)"
                                  >
                                    <v-list-item-title>{{
                                      number
                                    }}</v-list-item-title>
                                  </v-list-item>
                                </v-list>
                              </v-menu>
                            </v-col>-->

                            <v-col
                              cols="12"
                              class="d-flex justify-end align-center"
                            >
                              <span class="mr-4 grey--text">
                                Page {{ page }} of {{ numberOfPages }}
                              </span>
                              <v-btn
                                fab
                                dark
                                color="pink"
                                class="mr-1"
                                @click="formerPage"
                              >
                                <v-icon dark color="black" small>
                                  fas fa-chevron-left
                                </v-icon>
                              </v-btn>
                              <v-btn
                                fab
                                dark
                                color="pink"
                                class="ml-1"
                                @click="nextPage"
                              >
                                <v-icon dark color="black" small>
                                  fas fa-chevron-right
                                </v-icon>
                              </v-btn>
                            </v-col>
                          </v-row>
                        </template>
                      </v-data-iterator>
                    </v-col>
                  </div>
                </v-col>
              </v-col>
            </v-row>

            <!-- Hidden questions -->
            <AppHiddenQuestion
              top="67.75%"
              right="30%"
              question="How many charms are included in the Disney Park Exclusives this year?"
              :options="['Five', 'Three', 'Two']"
              answer="Two"
              :tracking-id="28"
            ></AppHiddenQuestion>

            <AppHiddenQuestion
              top="25%"
              right="19%"
              :tracking-id="34"
            ></AppHiddenQuestion>

            <AppHiddenQuestion
              top="11%"
              right="55%"
              :tracking-id="35"
            ></AppHiddenQuestion>
          </v-container>
        </div>
      </v-sheet>
    </aspect-ratio-layout>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import {
  ValidationProvider,
  ValidationObserver,
} from "vee-validate/dist/vee-validate.full.esm";
import ResponsiveLayout from "../layouts/ResponsiveLayout.vue";
import AspectRatioLayout from "../layouts/16by9Layout.vue";
import theatreMockData from "../assets/data/theatre-mock-data.json";
import downloadsMockData from "../assets/data/information-downloads-mock-data.json";
import axios from "axios";

import { isEmpty as _isEmpty } from "lodash-es";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    ResponsiveLayout,
    AspectRatioLayout,
  },

  data() {
    return {
      dialog: false,
      dialogFirst: false,
      dialogSecretSecond: false,
      dialogSecretThird: false,
      column: null,
      row: null,
      overlay: false,
      mockData: theatreMockData,
      downloadsMockData: downloadsMockData,
      itemsPerPageArray: theatreMockData.length,
      search: "",
      filter: {},
      sortDesc: false,
      page: 1,
      itemsPerPage: 100,
      sortBy: "name",

      breakoutrooms: [],

      filters: {},
    };
  },

  async mounted() {
    await this.getBreakoutrooms();
  },

  computed: {
    ...mapState([
      "colleagues",
      "activeChatId",
      "isChatOpen",
      "availableInterests",
      "event",
    ]),
    ...mapGetters(["me"]),

    numberOfPages() {
      return Math.ceil(this.colleagues.length / this.itemsPerPage);
    },
    filteredKeys() {
      return this.keys.filter((key) => key !== "Name");
    },

    filteredColleagues() {
      if (_isEmpty(this.filters)) {
        return this.colleagues;
      }

      return this.colleagues.filter((colleague) => {
        let matches = true;

        Object.entries(this.filters).forEach(([key, value]) => {
          if (!value) return;

          if (key === "interests") {
            if (value.length === 0) return;

            if (colleague?.[key]) {
              matches = colleague[key].some((interest) =>
                value.includes(interest)
              );
            } else {
              matches = false;
            }

            return;
          }

          if (!colleague[key].toLowerCase().includes(value.toLowerCase())) {
            matches = false;
          }
        });

        return matches;
      });
    },
  },

  watch: {
    "$store.getters.isAuthenticated": async function (newValue, oldValue) {
      console.log("isAuthenticated change:", newValue, "oldValue:", oldValue);
      if (newValue) {
        await this.onLogin();
      }
    },
  },

  methods: {
    onChatClick(chatId) {
      if (!this.isChatOpen) {
        this.$store.dispatch("toggleChat");
      }

      this.$store.commit("SET_ACTIVE_CHAT_ID", chatId);
    },
    async getBreakoutrooms() {
      const url = `${process.env.VUE_APP_API_URL}breakout/`;
      const { data } = await axios.get(url);

      this.breakoutrooms = data;
    },
    navigate() {
      const next = this.$route.query?.next;
      if (next) {
        this.$router.push(next);
      } else if (this.$store.getters.isAdmin) {
        this.$router.push({ name: "AdminHome" });
      } else {
        this.$router.push({ name: "Home" });
      }
    },
    async login() {
      this.error = false;
      try {
        await this.$store.dispatch("login", this.credentials);
      } catch (e) {
        this.error = true;
        console.debug("Login error:", e);
      }
    },
    async onLogin() {
      // Navigate
      this.navigate();
    },

    // playIntroductionVideo() {
    //   const playArrow = document.getElementsByClassName(
    //     "play-btn-arrow-introduction"
    //   )[0];

    //   if (this.isPlaying == false && this.$session.get("firstTime", true)) {
    //     playArrow.classList.remove("fa-play");
    //     playArrow.classList.add("fa-pause");

    //     this.$refs.introductionVideo.play();
    //     this.isPlaying = true;
    //   } else {
    //     this.$refs.introductionVideo.pause();
    //     this.isPlaying = false;

    //     playArrow.classList.remove("fa-pause");

    //     playArrow.classList.add("fa-play");
    //   }
    // },

    // playWelcomeVideo() {
    //   const playArrow = document.getElementsByClassName("play-btn-arrow")[0];

    //   if (this.isPlaying == false) {
    //     playArrow.classList.remove("fa-play");
    //     playArrow.classList.add("fa-pause");

    //     this.$refs.welcomeVideo.play();
    //     this.isPlaying = true;
    //   } else {
    //     this.$refs.welcomeVideo.pause();
    //     this.isPlaying = false;

    //     playArrow.classList.remove("fa-pause");

    //     playArrow.classList.add("fa-play");
    //   }
    // },

    nextPage() {
      if (this.page + 1 <= this.numberOfPages) this.page += 1;
    },
    formerPage() {
      if (this.page - 1 >= 1) this.page -= 1;
    },
    updateItemsPerPage(number) {
      this.itemsPerPage = number;
      console.log(this.mockData.length);
    },

    filteredKeysButton() {
      return this.keys.filter((key) => key !== "Name");
    },
  },
};
</script>

<style scoped>
/*.main {
  background-image: url("https://vod.eventcdn.net/pandora/smc2021/MeetYourColleagues_Bg.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

@media screen and (min-width: 1020px) {
  .main {
    background-image: url("https://vod.eventcdn.net/pandora/smc2021/MeetYourColleagues_Bg.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    backface-visibility: hidden;
  }
}*/

.v-application.livehouse-app .countdown-value {
  font-size: 5rem !important;
  color: var(--v-primary-base) !important;
  line-height: 5rem !important;
  font-weight: 700 !important;
}

@media screen and (max-width: 992px) {
  .v-application.livehouse-app .countdown-value {
    font-size: 3rem !important;
    line-height: 3.5rem !important;
  }
}

.countdown-with-pink-text {
  color: var(--pink) !important;
  font: var(--unnamed-font-style-normal) normal bold 80px/60px
    var(--unnamed-font-family-pan-text);
  letter-spacing: var(--unnamed-character-spacing-0);
  text-align: center;
  font: normal normal bold 80px/60px Pan Text;
}

.countdown-headline {
  font: var(--unnamed-font-style-normal) normal
    var(--unnamed-font-weight-normal) 22px/32px
    var(--unnamed-font-family-pan-text);
  letter-spacing: var(--unnamed-character-spacing-0);
  text-align: center;
  font: normal normal normal 22px/32px Pan Text;
}

.unit {
  font: var(--unnamed-font-style-normal) normal
    var(--unnamed-font-weight-normal) var(--unnamed-font-size-18) /
    var(--unnamed-line-spacing-22) var(--unnamed-font-family-pan-text);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--darker-grey);
  text-align: center;
  font: normal normal normal 18px/22px Pan Text;
}

.countdown-container {
  margin-top: 10.5rem;
}
</style>
